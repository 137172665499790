const GlobalError = () =>{
    return(
        <div id='global-error'>
            <h2>
                Error: unfortunately,<br/>
                something went a bit off.
            </h2>
            <div id='error-message-text'>
                We're having trouble generating your Custom <br/> Smile Plan. Please try again in a few minutes.<br/>If this issue persists, please e-mail or give us<br/> a call 
            </div>
            <div className="call-button-wrapper">
                <a id='telephone-number' href="tel:+1180-051-5198">
                    1800 515 198
                </a>
                <a id='email-support' href="mailto:feedback@smiledirectclub.com">
                    Email Support 
                </a>
            </div>
        </div>
    )
}

export default GlobalError
