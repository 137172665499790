import { ResizeObserver } from "@juggle/resize-observer"
import { Canvas } from "@react-three/fiber"
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef } from "react"
import * as THREE from 'three'
import { TApplicationActiveTab, TViewResetFlag, TViewStateAccumulator } from "../JsTpviewer"
import CameraLightController from "../Scene/CameraLightController"
import CameraPositionController from "../Scene/CameraPositionController"
import CameraViewStateController from "../Scene/CameraViewStateController"
import ViewTransformator from "../Scene/ViewTransformator"
import { TViewValue } from "../types"
import isMobile from "../Utils/is-mobile"
import SwitchBeforeAfter from "./SwitchBeforeAfter"
import ViewControls from "./ViewControls"

type TView3dBeforeAfterProps = {
    setCameraParameters      : (x: number, y: number, z: number, zoom: number) => void
    cameraPosition           : MutableRefObject<{
        position: THREE.Vector3
        zoom: number
    }>
    teethsModelsBefore  : JSX.Element | undefined                
    teethsModelsAfter   : JSX.Element | undefined    
    gingivaModelsBefore : JSX.Element | undefined
    gingivaModelsAfter  : JSX.Element | undefined    

    activeTab : TApplicationActiveTab
    setActiveTab: Dispatch<SetStateAction<TApplicationActiveTab>>
    currentView     : TViewValue
    setCurrentView  : Dispatch<SetStateAction<TViewValue>>
    isViewClicked   : boolean
    setViewClicked  : Dispatch<SetStateAction<boolean>>
    setStepIndex    : Dispatch<SetStateAction<number>>
    viewStateAccumulatorRef: React.MutableRefObject<TViewStateAccumulator>
    prevTabRef: React.MutableRefObject<TApplicationActiveTab>
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag>
}

type TVec2 = {
    x:number 
    y:number
}

const View3dBeforeAfter = (props: TView3dBeforeAfterProps) =>{

    const refBeforeAfter = useRef<any>(null)
    const beforeRef = useRef<any>(null)
    const afterRef = useRef<any>(null)

    
    const {
        setCameraParameters,

        teethsModelsBefore,
        teethsModelsAfter,
        gingivaModelsBefore,
        gingivaModelsAfter,

        activeTab,
        currentView,
        isViewClicked,
        setActiveTab,
        setCurrentView,
        setViewClicked,
        setStepIndex,
        viewStateAccumulatorRef,
        isNeedToResetViewRef
    } = props

    const isMouseDown    = useRef<boolean>(false)
    const mouseXYDefault:TVec2 = {x:-1, y:-1}
    const mouseXYStart   = useRef(mouseXYDefault)
    const touchesXYStart = useRef([mouseXYDefault,mouseXYDefault])    
    const mouseXYDelta   = useRef({x:0, y:0})
    const zoomWheelValue = useRef(0)
    

    const controlsMouseDown = useCallback((e:any)=>{
        isMouseDown.current = true
        if(isMobile()){
            e.preventDefault()
            if(typeof(e.touches)!=='undefined' && e.touches.length === 1){
                mouseXYStart.current = {x: e.touches[0].clientX, y: e.touches[0].clientY }
            }else if(typeof(e.touches)!=='undefined' && e.touches.length === 2){
                touchesXYStart.current = [
                    {x: e.touches[0].clientX, y: e.touches[0].clientY },
                    {x: e.touches[1].clientX, y: e.touches[1].clientY }
                ]
            }else if(typeof(e.clientX)!=='undefined' && typeof(e.clientY)!=='undefined' ){
                mouseXYStart.current = {x: e.clientX, y: e.clientY }
            }
        }else{
            mouseXYStart.current = {x: e.clientX, y: e.clientY }
        }
        
    },[])

    const controlsMouseUp = useCallback((e:any)=>{
        
        isMouseDown.current = false
        mouseXYStart.current = mouseXYDefault
        mouseXYDelta.current = {x:0, y:0}
        touchesXYStart.current = [mouseXYDefault,mouseXYDefault]
    },[])

    const controlsMouseMove = useCallback((e:any)=>{
        if(isMouseDown.current === true){
            if(isMobile() === false){
                let deltaXY:TVec2 = {
                    x: mouseXYStart.current.x - e.clientX ,
                    y: mouseXYStart.current.y - e.clientY ,
                }
                mouseXYDelta.current = deltaXY    
            }
            if(isMobile() === true){
                // MOVE VIEW
                if(typeof(e.touches)!=='undefined' && e.touches.length === 1){
                    let deltaXY:TVec2 =
                    {
                        x: mouseXYStart.current.x - e.touches[0].clientX,
                        y: mouseXYStart.current.y - e.touches[0].clientY
                    }
                    mouseXYDelta.current = deltaXY
                } else if(typeof(e.touches)!=='undefined' && e.touches.length === 2){
                    // ZOOM MOBILE VIEW
                    const lengthStart = Math.sqrt(
                        Math.pow( touchesXYStart.current[0].x - touchesXYStart.current[1].x, 2) + 
                        Math.pow( touchesXYStart.current[0].y - touchesXYStart.current[1].y, 2)
                    ) 
                    const lengthEnd   = Math.sqrt(
                        Math.pow( touchesXYStart.current[0].x - touchesXYStart.current[1].x, 2) + 
                        Math.pow( touchesXYStart.current[0].y - touchesXYStart.current[1].y, 2)
                    )
                    
                    const triggerValue = 10 // px

                    if(lengthStart > lengthEnd && ( lengthStart - lengthEnd ) > triggerValue){
                        zoomWheelValue.current = 1
                        setTimeout(()=>{zoomWheelValue.current = 0},20)
                    }
                    if(lengthStart < lengthEnd && ( lengthEnd - lengthStart ) > triggerValue){
                        zoomWheelValue.current = -1
                        setTimeout(()=>{zoomWheelValue.current = 0},20)
                    }
                }
            }
        }else{
            mouseXYDelta.current = {x:0, y:0}
        }
    },[])

    const controlsZoom = useCallback((e:any)=>{
        if(e.deltaY > 0){ zoomWheelValue.current = 1 }
        if(e.deltaY < 0){ zoomWheelValue.current = -1 }
        setTimeout(()=>{zoomWheelValue.current = 0},20)
    },[])

    useEffect(()=>{
        
        if(refBeforeAfter.current){

            refBeforeAfter.current.addEventListener( 'mousemove'  , controlsMouseMove )
            refBeforeAfter.current.addEventListener( 'mousedown'  , controlsMouseDown )
            refBeforeAfter.current.addEventListener( 'mouseup'    , controlsMouseUp   )
            refBeforeAfter.current.addEventListener( 'mouseout'   , controlsMouseUp   )

            refBeforeAfter.current.addEventListener( 'wheel'      , controlsZoom      )

            refBeforeAfter.current.addEventListener( 'touchmove'  , controlsMouseMove )
            refBeforeAfter.current.addEventListener( 'touchstart' , controlsMouseDown )
            refBeforeAfter.current.addEventListener( 'touchend'   , controlsMouseUp   )
            refBeforeAfter.current.addEventListener( 'touchcancel', controlsMouseUp   )

        }
        return(()=>{
            // refBeforeAfter.current.removeEventListener( 'mousemove', controlsMouseMove )
            // refBeforeAfter.current.removeEventListener( 'mousedown', controlsMouseDown )
            // refBeforeAfter.current.removeEventListener( 'mouseup'  , controlsMouseUp   )
            // refBeforeAfter.current.removeEventListener( 'mouseout' , controlsMouseUp   )
        })
    },[])


    return(
        <>  
            <div id='view-before-after'
                
            >
                <div className='view-controls-panel' 
                    ref={ refBeforeAfter }
                    onMouseOut = { controlsMouseUp }  
                />

                <div className="canvas-wrapper"
                    ref = { beforeRef }
                >
                    <div className='title' id='title_before'>
                        Before
                    </div>
                    <Canvas
                        resize={{ 
                            polyfill: ResizeObserver,
                            scroll: true, 
                            debounce: { 
                                scroll: 50, 
                                resize: 0 
                            }
                        }}
                        
                        gl={{ 
                            antialias: true,
                            autoClearColor: true,
                            toneMapping: THREE.NoToneMapping,
                        }}
                        
                        legacy
                        linear
                        orthographic
                    > 

                        <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,10]} />
                        <CameraLightController/>
                        <CameraViewStateController
                            view='before'
                            viewStateAccumulatorRef={ viewStateAccumulatorRef }
                        />
                        <CameraPositionController 
                            startPosition = { new THREE.Vector3(0,0,500) }
                            isMouseDown   = { isMouseDown                }
                            zoomWheelValue= { zoomWheelValue }
                            delta         = { mouseXYDelta               }
                            currentView   = { currentView                }
                        />
                        <hemisphereLight 
                            //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                            args={['#161111', '#161111']}
                            intensity={1}
                        />

                        { teethsModelsBefore  }

                        { gingivaModelsBefore }

                        <ViewTransformator
                            activeTab               = { activeTab               }
                            currentView             = { currentView             }
                            setCameraParameters     = { setCameraParameters     } 
                            isViewClicked           = { isViewClicked           }
                            viewStateAccumulatorRef = { viewStateAccumulatorRef }
                            isNeedToResetViewRef    = { isNeedToResetViewRef    }
                            viewType                = "before"
                        />

                    </Canvas>
                </div>

                <div className='label-anchor'>
                    <img id='label-3d' src="3d.png" alt="3d"/>
                </div>
                
                <div className="canvas-wrapper"
                    ref = {afterRef}
                >
                    <div className='title' id='title_after'>
                        After
                    </div>
                    <Canvas
                        resize={{ 
                            polyfill: ResizeObserver,
                            scroll: true, 
                            debounce: { 
                                scroll: 50, 
                                resize: 0 
                            }
                        }} 
                        gl={{ 
                            antialias: true,
                            autoClearColor: true,
                            toneMapping: THREE.NoToneMapping,
                        }}

                        legacy
                        linear
                        orthographic
                    > 
                        <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,100]} />
                        <CameraLightController/>
                        <CameraViewStateController
                            view='after'
                            viewStateAccumulatorRef={ viewStateAccumulatorRef }
                        />
                        <CameraPositionController 
                            startPosition = { new THREE.Vector3(0,0,500) }
                            isMouseDown   = { isMouseDown                }
                            zoomWheelValue= { zoomWheelValue }
                            delta         = { mouseXYDelta               }
                            currentView   = { currentView                }
                        />

                        <hemisphereLight 
                            //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                            args={['#161111', '#161111']}
                            intensity={1}
                        />

                        { teethsModelsAfter  }

                        { gingivaModelsAfter }

                        <ViewTransformator
                            activeTab           = { activeTab           }
                            currentView         = { currentView         }
                            setCameraParameters = { setCameraParameters }
                            isViewClicked       = { isViewClicked       }
                            viewStateAccumulatorRef={ viewStateAccumulatorRef }
                            isNeedToResetViewRef = { isNeedToResetViewRef   }
                            viewType = 'after'  
                        />

                    </Canvas>
                </div>

            </div>
            <div id='controls'>

                <ViewControls 
                    currentView          = { currentView            }
                    setCurrentView       = { setCurrentView         }
                    setViewClicked       = { setViewClicked         }
                    isNeedToResetViewRef = { isNeedToResetViewRef   }
                    viewControl          = 'before|after'
                />

                <SwitchBeforeAfter 
                    activeTab    = { activeTab    }
                    setActiveTab = { setActiveTab }
                    setStepIndex = { setStepIndex }
                />
            </div>
        </>
    )
}

export default View3dBeforeAfter
