import { useEffect } from "react"



// SHOW DEVICE INFORMATION IN CONSOLE

const Helper = () =>{
    useEffect(()=>{
        console.group('Device information')
        console.log( 'Browser window size:', window.innerWidth, 'X' , window.innerHeight)
        console.log( 'Device pixelRatio :', window.devicePixelRatio ) 
        console.groupEnd()
    },[])
    return(<></>)
}

export default Helper
