import JSZip from "jszip"
import { Dispatch, SetStateAction } from "react"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { TSteppedModelData, TTeethModelData } from "../types"
import JSZipUtils from "../Utils/jszip-utils"
import { mergeBufferGeometries } from "./BufferGeometryUtils"
import detectZipFormat from './detectZipFormat'
import * as THREE from 'three'


// UN-ZUP
                    

type TGetMultiZipObjModelDataProps = {
    urlArray: string[]
    setGingivaModelData  : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelGeometry: Dispatch< SetStateAction< TSteppedModelData      >>
    onGlobalError        : (errorString:string) => void
    onFinish?: () => void 
}

type TObjData = {
    name: string
    data: string
}

const getMultiZipObjModelsData = (handlers:TGetMultiZipObjModelDataProps) =>{
    const {
        urlArray,
        setGingivaModelData,
        setTeethModelGeometry,
        onGlobalError,
        onFinish
    } = handlers
    const sortedUrlArray = urlArray.sort()

    const zipLoadersPromissesArray:Promise<TObjData>[] = sortedUrlArray.map( (urlItem, index)=>{

        return(
            new Promise((resolve,reject)=>{
                JSZipUtils.getBinaryContent(urlItem, (err: any, data:ArrayBuffer) => {
                    if(err) {
                        console.log('CANT GET ZIP FILE')
                        onGlobalError('CANT GET ZIP FILE')
                        reject(err)
                    }else{

                        JSZip.loadAsync(data)
                        .then((openedArchive:JSZip) => {
                            const zipFormat = detectZipFormat(openedArchive)
        
                            switch(zipFormat){
                                case 'obj': {
                                    
                                    Object
                                    .entries(openedArchive.files)
                                    .map(item => item[1])
                                    .map((fileItem, index)=>{
                                        fileItem.async('text')
                                        .then((unzippedFile)=>{
                                            resolve({
                                                name: fileItem.name,
                                                data: unzippedFile
                                            })
                                        })
                                        .catch((e)=>{
                                            onGlobalError("Cant unzip file.")
                                            console.error("Cant unzip file")
                                        })
                                    })
                                } break
        
                                default:{
                                    onGlobalError("Zip file have unknown format")
                                    console.error("Zip file have unknown format")
                                } break
                            }
                        })

                        
                    }
                })
            })
        )
    })


    const teethModelData:TSteppedModelData = {
        upperSteps: [],
        lowerSteps: []
    }

    const gingivasModelData:TSteppedModelData = {
        upperSteps: [],
        lowerSteps: []
    }
    
    Promise.all(zipLoadersPromissesArray)
    .then((objData)=>{

        
        const sortedObjData = objData.sort((a,b)=>{
            return(a.name.localeCompare(b.name))
        })


        for(let i = 0; i< sortedObjData.length; i++){
            const parsedObj:THREE.Group = new OBJLoader().parse( sortedObjData[i].data )
            // COLLECT TEETH 3D-OBJECTS
            const upperTeeths:THREE.BufferGeometry[] = []
            const lowerTeeths:THREE.BufferGeometry[] = []
            
            let gingivaUpper:THREE.BufferGeometry | undefined 
            let gingivaLower:THREE.BufferGeometry | undefined 

            parsedObj.traverse((objItem:any)=>{
                if(objItem.geometry){
                    
                    objItem.geometry.name = objItem.name

                    if((objItem.name.indexOf('teeth')>-1 ||
                        objItem.name.indexOf('Tooth')>-1
                    )&&(
                        objItem.name.indexOf('_1')>-1 ||
                        objItem.name.indexOf('_2')>-1 ||
                        objItem.name.indexOf(' 1')>-1 ||
                        objItem.name.indexOf(' 2')>-1     
                    )){
                        upperTeeths.push(objItem.geometry)
                    }

                    if((objItem.name.indexOf('teeth')>-1 ||
                        objItem.name.indexOf('Tooth')>-1
                    )&&(
                        objItem.name.indexOf('_3')>-1 ||
                        objItem.name.indexOf('_4')>-1 ||
                        objItem.name.indexOf(' 3')>-1 ||
                        objItem.name.indexOf(' 4')>-1     
                    )){
                        
                        lowerTeeths.push(objItem.geometry)
                    }

                    if(objItem.name.indexOf('Mandible')>-1){
                        gingivaLower = objItem.geometry
                    }

                    if(objItem.name.indexOf('Maxilla')>-1){
                        gingivaUpper = objItem.geometry
                    }
                }
            })

            const mergedUpperTeethsGeometry = mergeBufferGeometries(upperTeeths)
            const mergedLowerTeethsGeometry = mergeBufferGeometries(lowerTeeths)

            teethModelData.upperSteps.push({
                name: 'Teeths_upper',
                data: mergedUpperTeethsGeometry
            })

            teethModelData.lowerSteps.push({
                name: 'Teeths_lower',
                data: mergedLowerTeethsGeometry
            })

            gingivasModelData.lowerSteps.push({
                name: 'Gingiva_lower',
                data: gingivaLower ? gingivaLower : new THREE.BufferGeometry()
            })

            gingivasModelData.upperSteps.push({
                name: 'Gingiva_upper',
                data: gingivaUpper ? gingivaUpper : new THREE.BufferGeometry()
            })

        }

        if(
            (
                gingivasModelData.lowerSteps.length === 0 ||
                gingivasModelData.upperSteps.length === 0 
            ) ||
            (
                teethModelData.lowerSteps.length === 0 ||
                teethModelData.upperSteps.length === 0
            )
        ){

            onGlobalError('ZIP-file content is empty')
        }else{
            setTeethModelGeometry(teethModelData)
            setGingivaModelData(gingivasModelData)
        }

        if( onFinish ){
            onFinish()
        }
    })
    .catch(()=>{
        onGlobalError("Cant parse OBJ from zip-files")
        console.error("Cant parse OBJ from zip-files")
    })
}

export default getMultiZipObjModelsData
