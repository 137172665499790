import { useMemo, useState, useEffect, useRef } from 'react'
import * as THREE from 'three'
import ModelLoader, { TTeethStepsPosition } from "./ModelLoader/ModelLoader"
import { TModelData, TSteppedModelData, TTeethModelData, TViewValue } from './types'
import Preloader from './Ui/Preloader'
import { mergeBufferGeometries } from './ModelLoader/BufferGeometryUtils'
import getZoomValue from './Utils/get-viewport-zoom-value'
import useInterval from './Utils/use-interval'
import View3dTimeline from './Ui/View3dTimeline'
import View3dBeforeAfter from './Ui/View3dBeforeAfter'
import ZipFileLoader from './Ui/ZipFileLoader'
import ModelFromFromGeometry from './Scene/ModelFromFromGeometry'
import Helper from './Utils/Helper'
import getCtmTransformedGeometry from './ModelLoader/getCtmTransformedGeometry'
import { useViewerState } from './Fsm/viewerStateMachine'
import getCasesArrayFromUrl from './Utils/getCasesFromUrl'

export const delayValue = 700
export const delayPauseValue = 500
export const delayToChangeViewValue = 10

export type TViewState = {
    cameraPosition : THREE.Vector3
    cameraRotation : THREE.Euler
}

export type TViewStateAccumulator = {
    main  : TViewState | undefined
    before: TViewState | undefined
    after : TViewState | undefined
}

export type TViewResetFlag = {
    main  : boolean
    before: boolean
    after : boolean
}

export type TViewBeforeAfter = "" | "BEFORE" | "AFTER"

export type TApplicationMode = 'CASE_FROM_URL_LOADING' | 'CASE_FROM_URL_DONE' | 'CASE_FROM_LOCAL_FILE' | 'CASE_FROM_LOCAL_FILE_PARSING' | 'CASE_FROM_LOCAL_FILE_DONE' 
export type TApplicationActiveTab = 'TIMELINE' | 'BEFORE_AFTER'

type TJsTpviewerProps = {
    onGlobalError: (errorString:string) => void
}

const JsTpviewer = (props: TJsTpviewerProps) => {

    const { viewerState, sendViewerEvent } = useViewerState()

    const { onGlobalError } = props

    const casesUrlArray = getCasesArrayFromUrl()

    const [ activeTab, setActiveTab] = useState<TApplicationActiveTab>('TIMELINE')
    const prevTabRef = useRef<TApplicationActiveTab>('TIMELINE')
    
    const viewForAutoPlay:TViewValue[] = ['front', 'top','bottom','left','right']

    const [ viewIndex                    , setViewIndex                    ] = useState(0)
    const [ tpStepIndex                  , setTpStepIndex                  ] = useState(0)
    const [ isPlayed                     , setIsPlayed                     ] = useState(false)
    const [ anumationLoopCounter         , setAnimationLoopCounter         ] = useState(0)
    const [ currentView                  , setCurrentView                  ] = useState<TViewValue>('front')

    const [ isViewClicked                , setViewClicked                  ] = useState(false) // disable autochange of view  if user change view

    const [ teethModelStepTransformation , setTeethModelStepTransformation ] = useState<TTeethStepsPosition[][]>([])
    
    const [ delayModelAnimation , setDelayModelAnimation ] = useState<null|number>(null) // interval size
    const [ delayPause          , setDelayPause          ] = useState<null|number>(null) // interval size
    const [ delayToChangeView   , setDelayToChangeView   ] = useState<null|number>(null) // interval size

    const [                              , setMouseMove                    ] = useState(false)
    
    const viewStateAccumulatorRef   = useRef<TViewStateAccumulator>({
        main   : undefined,
        after  : undefined,
        before : undefined,
    })
    const isNeedToResetViewRef = useRef<TViewResetFlag>({
        main   : false,
        after  : false,
        before : false,
    })
    const cameraPosition = useRef({
        position: new THREE.Vector3(0,0,100),
        zoom: getZoomValue(),
    })

    const cancelStartupAnimation = () =>{
        sendViewerEvent({type: 'event_stop'})
        setDelayPause(null)
        setDelayModelAnimation(null)
        setIsPlayed(false)
    }

    useEffect(()=>{
        if( casesUrlArray.length>0 ){
            sendViewerEvent({type: 'event_loading_from_url'})
        }else{
            sendViewerEvent({type: 'event_loading_from_local_file'})
        }

        window.addEventListener('mousedown',()=>{
            cancelStartupAnimation()
        })
        window.addEventListener('wheel',()=>{
            cancelStartupAnimation()
        })
        window.addEventListener('touchstart',()=>{
            cancelStartupAnimation()
        })

    },[])


    const setCameraParameters = (x:number, y:number, z:number, zoom: number) =>{
        cameraPosition.current = {
            position: new THREE.Vector3(x,y,z),
            zoom
        }
    }


    // teeths have base geometry for all steps, but transformed for every step
    const [ teethModelData, setTeethModelData ] = useState<TTeethModelData>({
        upper: [],
        lower: [],
    })

    const [ teethModelGeometry, setTeethModelGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    // gingiva have own geometry for every step
    const [ gingivaModelGeometry, setGingivaModeGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    const [smilewrapperInfo, setSmilewrapperInfo         ] = useState<string | undefined>()


    useEffect(()=>{
        const teethsteppedGeometry:TSteppedModelData = {
            upperSteps: [],
            lowerSteps: []
        }

        // CTM SCENARIO : Base geometry + few transformations matrixes
        if(teethModelStepTransformation.length > 0){
            
            teethModelStepTransformation.forEach((stepTransformation, stepIndex)=>{
                
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${stepIndex}-upper`,
                    data: getCtmTransformedGeometry( stepTransformation,teethModelData.upper )
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${stepIndex}-lower`,
                    data: getCtmTransformedGeometry( stepTransformation,teethModelData.lower )
                })

            })

            setTeethModelGeometry(teethsteppedGeometry)
            
        }else if(teethModelStepTransformation.length === 0 && ( teethModelData.lower.length > 0 || teethModelData.upper.length >0 )){
            for(let i = 0; i<teethModelData.lower.length; i++){
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${i}-upper`,
                    data: teethModelData.upper[i].data
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${tpStepIndex}-lower`,
                    data: teethModelData.lower[i].data
                })
            }
            setTeethModelGeometry(teethsteppedGeometry)
        } 
        
        return(()=>{
            teethsteppedGeometry.lowerSteps = []
            teethsteppedGeometry.upperSteps = []
        })
    },[teethModelData, teethModelStepTransformation])
    


    // ANIMATION START
    useEffect(()=>{
        if(viewerState.value === 'play_view'){
            if(viewIndex === 0){
                setDelayModelAnimation(anumationLoopCounter ===0 ? delayValue/2 : delayValue)
                setAnimationLoopCounter(prev => prev+1)
            }else{
                setDelayModelAnimation(delayValue)
            }
        }

        if(viewerState.value === 'pause_before_next_view'){
            setDelayPause(delayPauseValue)
            setDelayModelAnimation(null)
        }

        if(viewerState.value === 'changing_view'){
            if(viewIndex < viewForAutoPlay.length-1){
                setViewIndex(viewIndex+1)
                setCurrentView(viewForAutoPlay[viewIndex+1])
                
            }else{
                setViewIndex(0)
                setCurrentView(viewForAutoPlay[0])
            }
            setDelayToChangeView(delayToChangeViewValue)
        }

        if(viewerState.value === 'idle'){
            setDelayModelAnimation(null)
            setDelayPause(null)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[viewerState.value])

    // solution for problem (press view button but autoplay doesn't get new view and play previous one )
    useEffect(()=>{
        if(viewForAutoPlay.indexOf(currentView) !== viewIndex){
            setViewIndex(viewForAutoPlay.indexOf(currentView))
        }
    },[currentView])

    useInterval(()=>{
        if(viewerState.value === 'play_view'){
            if(tpStepIndex + 1 < gingivaModelGeometry.upperSteps.length){
                setTpStepIndex (tpStepIndex + 1)
            }else if(tpStepIndex === gingivaModelGeometry.upperSteps.length-1){
                if(viewIndex === 0 && anumationLoopCounter === 2){
                    cancelStartupAnimation()
                    setAnimationLoopCounter(prev => prev+1)
                }else{
                    sendViewerEvent({type: 'event_pause'})
                }
            }

        }
    },delayModelAnimation)

    useInterval(()=>{
        
        sendViewerEvent({type: 'event_change_view'})
        setDelayPause(null)
        setTpStepIndex (0)
    }, delayPause)

    useInterval(()=>{
        sendViewerEvent({type: 'event_play_view'})
        setDelayToChangeView(null)
    }, delayToChangeView)

    // ANIMATION END


    useEffect(()=>{
        if(isPlayed===true){ cancelStartupAnimation() }
    // eslint-disable-next-line
    },[activeTab])



    const teethsModels = useMemo(()=>{ return( // FOR TIMELINE
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {tpStepIndex}
        />
    )},[teethModelGeometry, tpStepIndex])

    const teethsModelsBefore = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {0}
        />
    )},[teethModelGeometry])


    const teethsModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {teethModelGeometry.upperSteps.length-1 }
        />
    )},[teethModelGeometry])

    const gingivaModels = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {tpStepIndex }
        />
    )},[gingivaModelGeometry, tpStepIndex])


    const gingivaModelsBefore = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = { 0 }
        />
    )},[gingivaModelGeometry])

    const gingivaModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {gingivaModelGeometry.upperSteps.length-1 }
        />
    )},[gingivaModelGeometry])

    return (
        <>
            {
                viewerState.value === 'loading' &&
                <Preloader/>
            }

            {
                viewerState.value === 'loading_from_url' &&
                <>
                    <Preloader/>
                    <ModelLoader 
                        url                              = { casesUrlArray                   }
                        setTeethModelData                = { setTeethModelData               }
                        setGingivaModelData              = { setGingivaModeGeometry          }
                        setTeethModelGeometry            = { setTeethModelGeometry           }         
                        setTeethModelStepTransformation  = { setTeethModelStepTransformation }
                        setSmilewrapperInfo              = { setSmilewrapperInfo             }
                        onGlobalError                    = { onGlobalError                   }
                        sendViewerEvent                  = { sendViewerEvent                 }
                    />
                </>
            }

            

            {
                viewerState.value === 'loading_from_local_file' && 
                <ZipFileLoader
                    setGingivaModeGeometry          = { setGingivaModeGeometry          }
                    setTeethModelData               = { setTeethModelData               }
                    setTeethModelStepTransformation = { setTeethModelStepTransformation }
                    setSmilewrapperInfo             = { setSmilewrapperInfo             }
                    onGlobalError                   = { onGlobalError                   }
                    sendViewerEvent                 = { sendViewerEvent                 }
                />
            }

            {
                ( 
                    viewerState.value === 'idle'                   ||
                    viewerState.value === 'play_view'              ||  
                    viewerState.value === 'changing_view'          ||
                    viewerState.value === 'pause_before_next_view' 
                ) && 
                <>  

                    {
                        activeTab==='TIMELINE' &&   
                            <View3dTimeline
                                gingivaModelGeometry   = { gingivaModelGeometry    }
                                gingivaModels          = { gingivaModels           }
                                teethsModels           = { teethsModels            }
                                currentView            = { currentView             } 
                                setCurrentView         = { setCurrentView          }
                                isViewClicked          = { isViewClicked           }
                                setViewClicked         = { setViewClicked          }
                                smilewrapperInfo       = { smilewrapperInfo        }
                                stepIndex              = { tpStepIndex             }
                                setStepIndex           = { setTpStepIndex          }
                                isPlayed               = { isPlayed                }
                                setIsPlayed            = { setIsPlayed             }
                                delay                  = { delayModelAnimation     }
                                setDelay               = { setDelayModelAnimation  }
                                activeTab              = { activeTab               }
                                setActiveTab           = { setActiveTab            }
                                viewStateAccumulatorRef= { viewStateAccumulatorRef }
                                prevTabRef             = { prevTabRef              }
                                isNeedToResetViewRef   = { isNeedToResetViewRef    }
                                sendViewerEvent        = { sendViewerEvent         }
                            />
                    }


                    {
                        activeTab==='BEFORE_AFTER' &&
                            <View3dBeforeAfter
                                setCameraParameters    = { setCameraParameters     }
                                cameraPosition         = { cameraPosition          }
                                teethsModelsBefore     = { teethsModelsBefore      }
                                teethsModelsAfter      = { teethsModelsAfter       }
                                gingivaModelsBefore    = { gingivaModelsBefore     }
                                gingivaModelsAfter     = { gingivaModelsAfter      }
                                activeTab              = { activeTab               }
                                setActiveTab           = { setActiveTab            }
                                currentView            = { currentView             }
                                setCurrentView         = { setCurrentView          }
                                isViewClicked          = { isViewClicked           }
                                setViewClicked         = { setViewClicked          }
                                setStepIndex           = { setTpStepIndex          }
                                viewStateAccumulatorRef= { viewStateAccumulatorRef }
                                prevTabRef             = { prevTabRef              }
                                isNeedToResetViewRef   = { isNeedToResetViewRef    }
                            />
                    }

                </>
            }

            <Helper />
            
        </>
    )
}

export default JsTpviewer
