import { useFrame } from "@react-three/fiber"
import { TViewStateAccumulator } from "../JsTpviewer"
import * as THREE from 'three'

type TCameraViewStateControllerProps = {
    viewStateAccumulatorRef: React.MutableRefObject<TViewStateAccumulator>
    view: 'main' | 'before' | 'after'
}

const CameraViewStateController = (props:TCameraViewStateControllerProps) =>{
    const {
        viewStateAccumulatorRef,
        view
    } = props

    useFrame(({camera}) => {
        if(viewStateAccumulatorRef && viewStateAccumulatorRef.current){
            if(view === 'main'){

                viewStateAccumulatorRef.current['before'] = {
                    cameraPosition: new THREE.Vector3().copy(camera.position),
                    cameraRotation: new THREE.Euler().copy(camera.rotation),
                }
                
                viewStateAccumulatorRef.current['after'] = {
                    cameraPosition: new THREE.Vector3().copy(camera.position),
                    cameraRotation: new THREE.Euler().copy(camera.rotation),
                }

            }else{

                viewStateAccumulatorRef.current['main'] = {
                    cameraPosition: new THREE.Vector3().copy(camera.position),
                    cameraRotation: new THREE.Euler().copy(camera.rotation),
                }
            }
        }
    })

    return (<></>)

}

export default CameraViewStateController
