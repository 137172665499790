import { useFrame, useThree } from "@react-three/fiber"
import * as THREE from 'three'
import { MutableRefObject, useEffect, useRef } from "react"
import { TViewValue } from "../types"
import { cameraDistance } from "./ViewTransformator"
import { TViewState } from "../JsTpviewer"

type TCameraPositionControllerProps = {
    startPosition: THREE.Vector3
    delta: MutableRefObject<{
        x: number
        y: number
    }> 
    isMouseDown: MutableRefObject<boolean>
    zoomWheelValue: MutableRefObject<number>
    currentView: TViewValue
    cameraState?: MutableRefObject<TViewState | undefined>
}

const CameraPositionController = (props:TCameraPositionControllerProps) =>{

    const { 
        startPosition,
        delta,
        isMouseDown,
        currentView,
        zoomWheelValue
    } = props

    const  counter = useRef(false)
    const  prevMouseDown = useRef(false)
    const  cameraVector = useRef<THREE.Vector3 | undefined>(new THREE.Vector3() )

    
    const  isNeedToUpdateCameraPosition = useRef(false)

    useEffect(()=>{
        isNeedToUpdateCameraPosition.current = true
        cameraVector.current = new THREE.Vector3(0,0,cameraDistance)
        delta.current = {x:0,y:0}
    },[currentView])


    useFrame(({gl,camera, scene}) => {
        
        // INIT CAMERA POSITION
        if(counter.current === false){
            counter.current = true
            camera.position.copy(startPosition)
            camera.lookAt(0,0,0)
            camera.updateProjectionMatrix()
            cameraVector.current?.copy(startPosition)
            
            prevMouseDown.current = isMouseDown.current
        }

        if(isNeedToUpdateCameraPosition.current === true){
            isNeedToUpdateCameraPosition.current = false
            cameraVector.current?.copy(camera.position)
        }

        // START CAMERA ROTATION
        if( prevMouseDown.current === false && isMouseDown.current === true ){
            if(
                camera.position.x !== startPosition.x && 
                camera.position.y !== startPosition.y && 
                camera.position.z !== startPosition.z 
            ){
                cameraVector.current?.copy(camera.position)
            }
            prevMouseDown.current = true
        }

        // ROTATION AROUND CENTER (0,0,0)
        if( prevMouseDown.current === true && isMouseDown.current === true ){

            const drX = (delta.current.x / 10000)*180/Math.PI // rotation in radians
            const drY = (delta.current.y / 10000)*180/Math.PI
            
            const cameraDirecton = new THREE.Vector3().copy(cameraVector.current ? cameraVector.current : startPosition)
            const cameraDistance = cameraDirecton.length()
            
            cameraDirecton.normalize()
            cameraDirecton.applyAxisAngle( new THREE.Vector3(1,0,0),drY)
            cameraDirecton.applyAxisAngle( new THREE.Vector3(0,1,0),drX)
            cameraDirecton.setLength(cameraDistance)
            
            camera.position.copy(cameraDirecton)
            camera.lookAt(0,0,0)
            prevMouseDown.current = true
        }

        // END CAMERA ROTATION
        if(prevMouseDown.current === true && isMouseDown.current === false){
            isNeedToUpdateCameraPosition.current = true
            prevMouseDown.current = false
            cameraVector.current?.copy(camera.position)
        }
        
        if(zoomWheelValue.current!==0){
            camera.zoom += 0.25*zoomWheelValue.current
            camera.updateProjectionMatrix()
        }
    })

    return(<></>)
}

export default CameraPositionController